const feedbackForm = document.forms.feedbackForm;

feedbackForm?.addEventListener('submit', onFeedbackFormSubmit);

function onFeedbackFormSubmit(e) {
  e.preventDefault();

  const data = {
    name: feedbackForm?.elements?.name?.value,
    tel: feedbackForm?.elements?.tel?.value,
    msg: feedbackForm?.elements?.msg?.value,
    type: "feedback",
  }

  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));

  sendOrder(formData);
  e.target.reset();
}

function sendOrder(formData) {
  let sendURL = '/order.php';
  try {
    let response = fetch(sendURL, {
      method: "POST",
      body: formData,
    });
    window.location.href = sendURL;
  } catch (error) {
    console.log("error")
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const phoneInput = feedbackForm?.elements.tel;

  // Встановлюємо початкове значення
  // phoneInput.value = '+38 (';

  phoneInput.addEventListener('focus', function() {
    if (phoneInput.value === '') {
      phoneInput.value = '+38 (';
    }
  });

  phoneInput.addEventListener('input', function(e) {
    let value = phoneInput.value.replace(/\D/g, ''); // Видаляємо всі нецифрові символи
    value = value.substring(0, 12); // Обмежуємо довжину введення до 12 цифр (+38 і 10 цифр номера)

    // Форматуємо значення
    let formattedValue = '+38 (';
    if (value.length > 2) {
      formattedValue += value.substring(2, 5);
    }
    if (value.length > 5) {
      formattedValue += ') ' + value.substring(5, 8);
    }
    if (value.length > 8) {
      formattedValue += '-' + value.substring(8, 10);
    }
    if (value.length > 10) {
      formattedValue += '-' + value.substring(10, 12);
    }

    phoneInput.value = formattedValue;
  });

  phoneInput.addEventListener('keydown', function(e) {
    // Забороняємо користувачу видаляти маску
    if (e.key === 'Backspace' && phoneInput.value.length <= 5) {
      e.preventDefault();
    }
  });

  phoneInput.addEventListener('click', function(e) {
    // Встановлюємо курсор після "+38 ("
    if (phoneInput.selectionStart < 5) {
      phoneInput.setSelectionRange(5, 5);
    }
  });
});

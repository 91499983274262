import { hideElement, showElement } from '/src/common/js';
// import sendMessage from '/src/services/telegram';
// === modal ===
const orderModal = document.querySelector('[data-modal="order"]');
const orderModalBackdrop = orderModal?.querySelector('[data-modal-backdrop]');
const orderModalBtnClose = orderModal?.querySelector('[data-modal-btn-close]');

const orderImgRef = orderModal?.querySelector('[data-order-img]');
const orderTitleRef = orderModal?.querySelector('[data-order-title]');
const orderWeightRef = orderModal?.querySelector('[data-order-weight]');
// === counter ===
const counterRef = orderModal.querySelector('[data-counter]');
const counterValueRef = orderModal.querySelector('[data-counter-value]');
const orderTotalRef = orderModal.querySelector('[data-order-total]');
const orderSuccessRef = orderModal.querySelector('[data-order-success]');

// === form ===
const orderForm = document.forms.orderForm;

document.addEventListener('click', checkBtnAndOpenModal);
counterRef?.addEventListener('click', onCounterBtnClick);

orderForm?.addEventListener('submit', onOrderFormSubmit);

function checkBtnAndOpenModal(e) {
  const isOrderBtn = e.target.hasAttribute('data-order-btn');
  if (!isOrderBtn) return;
  setOrderData(e);
  openModal();
}
export function openModal() {
  showElement(orderModal);
  document.body.classList.add('modalIsOpen');

  orderModalBackdrop?.addEventListener('click', onBackdropClick);
  orderModalBtnClose?.addEventListener('click', closeModal);
  window.addEventListener('keydown', onEscKeyPress);
}
export function closeModal() {
  hideElement(orderModal);
  hideElement(orderSuccessRef);
  document.body.classList.remove('modalIsOpen');

  orderModalBackdrop?.removeEventListener('click', onBackdropClick);
  orderModalBtnClose?.removeEventListener('click', closeModal);
  window.removeEventListener('keydown', onEscKeyPress);
}

function onBackdropClick(e) {
  if (e.currentTarget === e.target) closeModal();
}
function onEscKeyPress(e) {
  if (orderModalBackdrop?.classList.contains('isHidden')) return;
  if (e.code === 'Escape') closeModal();
}

function setOrderData(e) {
  const orderBtn = e.target;
  const orderData = {
    img: orderBtn?.dataset?.orderImg,
    title: orderBtn?.dataset?.orderTitle,
    weight: orderBtn?.dataset?.orderWeight,
    price: orderBtn?.dataset?.orderPrice,
  };
  // === img ===
  orderImgRef?.setAttribute('alt', `${orderData?.title}`);
  orderImgRef?.setAttribute(
    'src',
    `${orderData?.img?.replace(/\/(src|products|sets)\//g, '/')}`
  );
  orderImgRef?.setAttribute(
    'data-order-img',
    `${orderData?.img?.replace(/\/(src|products|sets)\//g, '/')}`
  );
  // === title ===
  orderTitleRef?.setAttribute('data-order-title', `${orderData?.title}`);
  orderTitleRef.innerHTML = `${orderData?.title}`;
  // === weight ===
  orderWeightRef?.setAttribute('data-order-weight', `${orderData?.weight}`);
  orderWeightRef.innerHTML = `${orderData?.weight}`;
  // === price ===
  orderTotalRef?.setAttribute('data-order-price', `${orderData?.price}`);
  orderTotalRef?.setAttribute('data-order-total', `${orderData?.price}`);
  orderTotalRef.innerHTML = `${orderData?.price}`;

  // === counter reset ===
  counterValueRef?.setAttribute('data-counter-value', 1);
  counterValueRef?.setAttribute('value', 1);
  counterValueRef.value = 1;
}

function onCounterBtnClick(e) {
  const counterRef = e.target?.dataset?.counter;
  if (!(counterRef === 'decrement' || counterRef === 'increment')) return;

  const orderTotalRef = document.querySelector('[data-order-total]');
  const counterValueRef = orderForm?.elements?.counter;

  const orderPrice = Number(orderTotalRef?.dataset?.orderPrice);
  const counterValue = Number(counterValueRef?.value);
  let newCounterValue = counterValue;

  switch (counterRef) {
    case 'increment':
      newCounterValue = counterValue + 1;
      break;
    case 'decrement':
      newCounterValue = newCounterValue === 1 ? 1 : counterValue - 1;
      break;

    default:
      break;
  }

  const newOrderTotal = orderPrice * newCounterValue;
  counterValueRef.value = newCounterValue;
  counterValueRef.setAttribute('value', newCounterValue);
  counterValueRef.setAttribute('data-counter-value', newCounterValue);
  orderTotalRef.setAttribute('data-order-total', newOrderTotal);
  orderTotalRef.innerHTML = newOrderTotal;
}

// Функція для запуску reCAPTCHA
function onOrderFormSubmit(e) {
  e.preventDefault();
  // Запуск reCAPTCHA для перевірки
  console.log('Запуск reCAPTCHA для перевірки');
  grecaptcha
    .execute('6LfRmXoqAAAAADYBF1iYelqDj3odexoU7OM6CQrW', { action: 'submit' })
    .then(function (token) {
      // Якщо reCAPTCHA пройдена, продовжуємо з відправкою форми
      onOrderFormSubmitNext(e);
    })
    .catch(function (error) {
      // Якщо reCAPTCHA не вдалося пройти, виводимо повідомлення
      alert('Ви бот. Перевірка не пройдена.');
    });
}

function onOrderFormSubmitNext(e) {
  e.preventDefault();

  const data = {
    title: orderTitleRef?.dataset.orderTitle,
    weight: orderWeightRef?.dataset.orderWeight,
    count: orderForm?.elements?.counter?.value,
    price: `${orderTotalRef?.dataset?.orderPrice}`,
    total: `${orderTotalRef?.dataset?.orderTotal}`,
    name: orderForm?.elements?.name?.value,
    tel: orderForm?.elements?.tel?.value,
    type: 'order',
  };

  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));

  sendOrder(formData);
  e.target.reset();
}

function sendOrder(formData) {
  let sendURL = '/order.php';
  try {
    let response = fetch(sendURL, {
      method: 'POST',
      body: formData,
    });
    window.location.href = sendURL;
    // if (response.ok) { }
  } catch (error) {
    console.log('error');
  }
}

document.addEventListener('DOMContentLoaded', function () {
  const phoneInput = orderForm?.elements.tel;

  // Встановлюємо початкове значення
  // phoneInput.value = '+38 (';

  phoneInput.addEventListener('focus', function () {
    if (phoneInput.value === '') {
      phoneInput.value = '+38 (';
    }
  });

  phoneInput.addEventListener('input', function (e) {
    let value = phoneInput.value.replace(/\D/g, ''); // Видаляємо всі нецифрові символи
    value = value.substring(0, 12); // Обмежуємо довжину введення до 12 цифр (+38 і 10 цифр номера)

    // Форматуємо значення
    let formattedValue = '+38 (';
    if (value.length > 2) {
      formattedValue += value.substring(2, 5);
    }
    if (value.length > 5) {
      formattedValue += ') ' + value.substring(5, 8);
    }
    if (value.length > 8) {
      formattedValue += '-' + value.substring(8, 10);
    }
    if (value.length > 10) {
      formattedValue += '-' + value.substring(10, 12);
    }

    phoneInput.value = formattedValue;
  });

  phoneInput.addEventListener('keydown', function (e) {
    // Забороняємо користувачу видаляти маску
    if (e.key === 'Backspace' && phoneInput.value.length <= 5) {
      e.preventDefault();
    }
  });

  phoneInput.addEventListener('click', function (e) {
    // Встановлюємо курсор після "+38 ("
    if (phoneInput.selectionStart < 5) {
      phoneInput.setSelectionRange(5, 5);
    }
  });
});
